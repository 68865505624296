import React from 'react'
import { useState } from 'react'

export const AllProjectsNavBar = ({navButton}) => {
    const [catalogSection, setCatalogSection] = useState("Building")



    return ( 
      <div className='flex justify-center  md:justify-between mt-6 mb-8'> 
          <ul className='flex '>
              <li><button className={`mr-3  px-7 py-1.5 transition-colors duration-100 font-light rounded-md  ${catalogSection === "Building" ? "bg-orange text-white" : "bg-[#FFF] text-black" }`} onClick={()=>{setCatalogSection("Building")}} >{navButton[0].name}</button></li>
              {/* <li><button className={`mr-3  px-7 py-1.5 transition-colors duration-100 font-light rounded-md  ${catalogSection === "Infrastructure" ? "bg-orange text-white" : "bg-[#FFF] text-black" }`} onClick={()=>{setCatalogSection("Infrastructure")}} >Infrastructure</button></li>
              <li><button className={`mr-3  px-7 py-1.5 transition-colors duration-100 font-light rounded-md  ${catalogSection === "Energy" ? "bg-orange text-white" : "bg-[#FFF] text-black" }`} onClick={()=>{setCatalogSection("Energy")}} >Energy</button></li> */}
         </ul>
  
  
      </div>
    )
}
