import React, { useState } from "react";
import { ButtonDarkDownload } from "../UI/Inputs/ButtonDarkDownload";
import { Link } from "react-router-dom";

export const ContactUs = ({ DocumentDownload, getInTouch }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedButtons, setSelectedButtons] = useState([false, false, false]);

  // Define static file URLs relative to the public folder
  const fileUrls = [
    '/Assets/Files/AL_YAFOUR_COMPANY_PROFILE_2024.pdf', // URL for button 1
    '/Assets/Files/AL_YAFOUR_COMPANY_PROFILE_2024.pdf', // URL for button 2
    '/Assets/Files/AL_YAFOUR_COMPANY_PROFILE_2024.pdf'  // URL for button 3
  ];

  const handleButtonClick = (index) => {
    const newSelectedButtons = [...selectedButtons];
    newSelectedButtons[index] = !newSelectedButtons[index];
    setSelectedButtons(newSelectedButtons);
  };

  const isFormValid = name && email;
  const isAnyButtonSelected = selectedButtons.includes(true);

  const downloadFile = (fileUrl, index) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileUrl.split('/').pop(); // Extract the file name from the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Create the payload
    const payload = {
      data: {
        Name: name,
        Email: email,
      },
    };

    try {
      // Send POST request
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/Pdf-requests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      // Clear the form fields
      setName('');
      setEmail('');
      setSelectedButtons([false, false, false]); // Reset button selections

      // Trigger file downloads for selected buttons
      selectedButtons.forEach((isSelected, index) => {
        if (isSelected) {
          const fileUrl = fileUrls[index];
          if (fileUrl) {
            setTimeout(() => {
              downloadFile(fileUrl, index);
            }, 100 * index); // Small delay to ensure browser handles multiple downloads
          } else {
            console.warn(`No URL defined for button ${index + 1}`);
          }
        }
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="bg-white contactus">
        <div className="text-white bg-[#CFCFCF] contactus mt-20">
          <div className="flex flex-row border-b-2 border-[#afb0af0e] justify-between">
            <div className="w-12/12 xl:w-9/12 m-auto md:flex justify-between container py-[3.75rem]">
              <div className="w-full p-4 md:w-7/12 px-2">
                <h1 className="md:text-5xl md:text-start text-4xl text-black text-center uppercase">
                  {getInTouch.blacktext} <span className="text-orange">{getInTouch.orangeText}</span>
                </h1>
              </div>
              <div className="w-full p-4 md:w-4/12">
              <Link to="/contactus">  <button
                  type="button"
                  className="w-full bg-orange font-semibold rounded-md text- px-4 py-4 tracking-wider"
                >
                 {getInTouch.contactUsbutton}
                </button></Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-6">
          <div className="md:flex w-full xl:w-10/12 m-auto">
            <div className="w-full p-4 md:w-5/12">
              <h2 className="text-2xl text-black uppercase font-medium">{DocumentDownload.title}</h2>
              <ul className="mt-6 text-white">
                {DocumentDownload.downloadButton.map((btn, index) => (
                  <li key={index} className="mb-6">
                    <ButtonDarkDownload
                      text={btn.text}
                      isSelected={selectedButtons[index]}
                      onClick={() => handleButtonClick(index)}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full m-4 p-4 md:w-7/12">
              <form className="text-black space-y-4" onSubmit={handleSubmit}>
                <p>{DocumentDownload.inputHeader}</p>
                <input
                  type="text"
                  placeholder={DocumentDownload.namePlaceHolder + '*'}
                  className="w-full rounded-md py-3 px-4 border-[1px] border-black bg-white text-sm"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="email"
                  placeholder={DocumentDownload.emailPlaceHolder + '*'}
                  className="w-full rounded-md py-3 px-4 bg-white border-[1px] border-black text-sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="flex">
                  <p className="text-sm w-[50%] text-[#AFB0AF] mr-8 mt-3">
                    {DocumentDownload.hint}
                  </p>
                  <button
                    type="submit"
                    disabled={!isFormValid || !isAnyButtonSelected}
                    className={`w-[50%] text-white ${!isFormValid || !isAnyButtonSelected ? 'bg-gray-400' : 'bg-orange'} hover:bg-blue-600 font-semibold rounded-md text-sm px-4 py-3 tracking-wider`}
                  >
                    {DocumentDownload.button}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
