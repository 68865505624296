import React from 'react'
import { OurNumbers } from './OurNumbers'
import { OurPartners } from './OurPartners'
import { ContactUs } from './ContactUs'
import { BottomFooter } from './BottomFooter'
import { useFetchContent } from '../../Utils/CustomHooks/useFetchContent'
import { Loader } from '../Utilities/Loader'

export const AppFooter = () => {


  const { isPending, error, data, isLoading } =  useFetchContent('/footer?populate=deep', 'footer')
   if (isPending) return <Loader></Loader>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {successPartners,getInTouch,DocumentDownload,infoFooter,navList,socialMedia} = data.data.attributes
      


  




  return (
   <>
        
        <OurPartners successPartners={successPartners}></OurPartners>
        <ContactUs DocumentDownload={DocumentDownload} getInTouch={getInTouch}></ContactUs>
        <BottomFooter infoFooter={infoFooter} navList={navList} socialMedia={socialMedia}></BottomFooter>
   </>
  )
}
