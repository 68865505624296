import React from 'react'
import { GenericHeader } from '../Components/MainComponents/GenericHeader'
import FAQ from '../Components/MainComponents/FaqComponent/FAQ'
import { Loader } from '../Components/Utilities/Loader'
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent'


export const GeneralDirectorSeeMore = () => {


  const { isPending, error, data, isLoading } =  useFetchContent('/general-director-word?populate=deep', 'GeneralDirectorSeeMore')
  if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {Title,previewDescription,BlogText,image,Header} = data.data.attributes
      

  return (
    <>
    <GenericHeader Header={Header} headerType={"abtHeader"} subtitle={"Home / General Director"} title={"General Director"}></GenericHeader>

    <div className=' container mt-20' >
    <div className='md:flex md:px-0 px-3 justify-between xl:w-10/12 w-12/12 m-auto'>
        <div className='md:w-6/12 w-full mb-10 '>
            <h1 className='text-3xl font-semibold mb-3  '>{Title}</h1>
            
            <p className='leading-9  font-light'>{previewDescription}</p>
    
        </div>
        <div className=''>
            <img className=' h-[400px] w-full' src='Assets/Images/HomePage/DirectorImgSmall.png'>

            </img>
        </div>
      
    </div>
    <div className='md:px-0 px-2  md:flex md:text-start text-center xl:w-10/12 w-12/12 m-auto mt-5'>
    <p className='leading-9  font-light'>{BlogText}
</p>
    </div>
    </div>
    <div className='container'>
  
  </div>
    </>
  )
}
