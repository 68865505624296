import React, { useEffect, useState } from 'react'
import AlYafourWordLogoBlack from '../UI/Brand/AlYafourWordLogoBlack';
import AlYafourWordLogoWhite from '../UI/Brand/AlYafourWordLogoWhite';
import { ButtonRounded } from '../UI/Inputs/ButtonRounded';
import NavListItem from '../UI/NavBarElements/NavListItem';
import { ButtonRoundedWithIcon } from '../UI/Inputs/ButtonRoundedWithIcon';
import { LanguageToggleButton } from '../UI/Inputs/LanguageToggleButton';
import { NavListDropDownItem } from '../UI/NavBarElements/NavListDropDownItem';
import { NavBarHamburgerMenu } from '../UI/NavBarElements/NavBarHamburgerMenu';
import { Link } from 'react-router-dom';
import { setCookie } from '../../Utils/cookies';
import { useFetchContent } from '../../Utils/CustomHooks/useFetchContent';
import Cookies from 'js-cookie';
import { Loader } from '../Utilities/Loader';
const NavBar = () => {
  const [isTransparent, setIsTransparent] = useState(true);
  const [isDark, setIsDark] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsTransparent(scrollY === 0);
      setIsDark(!(scrollY === 0));
  };

  const handleHover = () => {
      if (window.scrollY === 0) {
          setIsTransparent(false);
          setIsDark(true);
      }
  };

  const handleLeave = () => {
      if (window.scrollY === 0) {
          setIsTransparent(true);
          setIsDark(false);
      }
  };

  useEffect(() => {
      const listener = window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', listener);
  }, []);

  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };
  const toggleLang= () => {
    setCookie('lang', 'ar', 365);
    window.location.reload();
    }



    const { isPending, error, data, isLoading } =  useFetchContent('/nav-bar?populate=deep', 'navbar')
    if (isPending) return <Loader></Loader>
    
    if (error) return 'An error has occurred: ' + error.message
  
    let  {navlistbutton,navButton,button,image} = data.data.attributes
        
  



  return (
      <>
          <nav
              className={`fixed top-0 left-0 w-full drop-shadow-xl z-50 px-6 py-4 transition duration-500 ease-in-out flex items-center ${
                  isTransparent  ? 'bg-transparent text-white' : 'bg-white text-black'
              } `}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
          >
              {/* Brand (can be replaced with your logo) */}
            {isDark ?  <AlYafourWordLogoWhite isDark={isDark}/> : <AlYafourWordLogoWhite isDark={isDark} /> }

              {/* Hamburger menu button for small screens */}
              <div className={`flex items-center  ${Cookies.get('lang') === 'en' ? 'ml-auto' : 'mr-auto'} lg:hidden`}>
                  <button onClick={toggleMenu} className="text-black">
                    {isDark ?<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                      </svg> :<svg className="w-6 h-6" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                      </svg>}  
                  </button>
              </div>

              {/* Navigation items for large screens */}
              <ul className={`hidden mx-auto lg:flex ${Cookies.get('lang') === 'en' ? 'space-x-4' : 'space-x-9'}`}>


                     { navlistbutton.map((listItem,index) => (<NavListDropDownItem
                     isDark={isDark} isMenuOpen={isMenuOpen}
                     hasDropdown={true}
                     dropdownItems={listItem.list}
                     
                     ><Link to={listItem.link}>{listItem.name}</Link>
                    </NavListDropDownItem>))

}




          
                  <NavListItem isDark={isDark} isMenuOpen={isMenuOpen}><Link to="/beasupplier">{navButton[0].name}</Link></NavListItem>
                  <NavListItem isDark={isDark} isMenuOpen={isMenuOpen}><Link to="/contactus">{navButton[1].name}</Link></NavListItem>
              </ul>

              {/* Right side buttons for large screens */}
              <div className="items-center hidden space-x-4 lg:flex">
                  <ButtonRoundedWithIcon Icon={"Assets/Icons/EmployeeIcon.svg"}>{button}</ButtonRoundedWithIcon>
                  <LanguageToggleButton isDark={isDark} toggle={toggleLang} isMenuOpen={isMenuOpen} >En</LanguageToggleButton>
              </div>
          </nav>

          {/* Mobile menu */}
          {isMenuOpen && (
             <NavBarHamburgerMenu navButton={navButton} navlistbutton={navlistbutton} toggle={toggleLang} isDark={isDark} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} ></NavBarHamburgerMenu>
          )}
      </>
  );
};
  
export default NavBar