import Cookies from 'js-cookie'
import React from 'react'

export const Stat = ({title,desc,number}) => {
  return (
    <div className="lg:w-6/12 mb-4 ">
            <div className={`${Cookies.get("lang")=="en"?"border-l-orange border-l-2":"border-r-orange border-r-2"} border-orange px-1 py-1 mb-1  text-xl`}>
              {" "}
              <h1 className="font-medium">{title}</h1>
            </div>
            <p className="text-sm  leading-6">
             {desc}
            </p>
            <h1 className="text-2xl mt-3"> {number}</h1>
          </div>
  )
}
