import React from 'react'
import { InfoCardWithCarousel } from '../Components/MainComponents/ProjectPage/InfoCardWithCarousel'
import { ProjectDetailsHeader } from '../Components/MainComponents/ProjectPage/ProjectDetailsHeader'
import { AboutProject } from '../Components/MainComponents/ProjectPage/AboutProject'
import { GenericHeader } from '../Components/MainComponents/GenericHeader'
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent'
import { Loader } from '../Components/Utilities/Loader'

export const ProjectPage = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get('id');

  const { isPending, error, data, isLoading } =  useFetchContent(`/projects/${id}?populate=deep`, 'projectDetails')
   if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {name,description,images,area,location,cost,type,consultant,Header,smallDescription,blogText} = data.data.attributes
      
  return (
    <>
         <GenericHeader Header={Header} headerType={"abtHeader"} title={"About Us"} subtitle={"Home  /  About Us"}></GenericHeader>

    <InfoCardWithCarousel title={name} smallDescription={smallDescription}  desc={description} images={images.data}></InfoCardWithCarousel>
    <AboutProject area={area} location={location} blogText={blogText} cost={cost} type={type} consultant={consultant}></AboutProject>
    
    
    </>
  )
}
