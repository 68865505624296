import React from 'react'
import { DivSpacer } from '../../Utilities/DivSpacer'
import { ButtonRoundedWithIconLeft } from '../Inputs/ButtonRoundedWithIconLeft'
import { ButtonWatch } from '../Inputs/ButtonWatch'
import { useTranslation, Trans } from 'react-i18next'
import {
  useQuery,
} from '@tanstack/react-query'
import axiosInstance from '../../../Utils/axiosConfig'
import { useFetchContent } from '../../../Utils/CustomHooks/useFetchContent'
import { Loader } from '../../Utilities/Loader'
import { ErrorPage } from '../../Utilities/ErrorPage'
export const SloganSpeech = ({text}) => {
    


  const { isPending, error, data, isLoading } =  useFetchContent('/home-page?populate=Slogan.title', 'Slogan')
   if (isPending) return <Loader/>
  
  if (error) return <ErrorPage errorMessage={error.message}/>

  let  Slogan = data.data.attributes.Slogan
       console.log(Slogan);


  return (
    <div className='container'>
     <div className='xl:w-10/12 w-12/12  m-auto'>
      <div className='flex md:justify-start md:mx-0 mx-0.5 md:text-start justify-center text-center items-center  h-screen  '>
          <div className='flex  flex-col'>  
            <h1 className='font-medium text-white text-5xl md:text-7xl'> {Slogan.title.text1} <span className='text-orange'>{Slogan.title.orange}</span>  <br/>{Slogan.title.text2}  </h1>
            <p className='md:text-xl font-normal  text-white mt-7' >{Slogan.subTitle}</p>
            <div className='flex md:justify-start justify-center'>
            {/* <ButtonWatch  Icon={"Assets/Icons/RightArrow.svg"} >{Slogan.VideoButton}</ButtonWatch> */}
            </div>
          </div>
        </div>
     </div>
    </div>
  )
}
