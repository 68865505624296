import React from "react";
import { ImageCardWithOffsettedBorder } from "./ImageCardWithOffsettedBorder";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export const MissionAndVison = ({mission,vision}) => {
  return (
    <>
      <div className="bg-white   pt-24 pb-24 lg:flex mt-20 items-center justify-center">
        {" "}
        <div>
        <div className="lg:flex container items-center   justify-between mb-28  w-10/12 lg:w-12/12">
          <div className=" w-12/12 lg:w-6/12">
          <h1 className="text-4xl lg:text-6xl mb-8 font-medium">{mission.Title}</h1>
            <p className="lg:text-lg leading-10">
             {mission.previewDescription}
        <Link to={`/seemore?id=mission`} className='text-orange  pt-1 transition-colors duration-300 m-auto  flex   mt-6 items-center hover:text-black '><div>{mission.seeMoreText}</div> <span ><img className={`mx-2 ${Cookies.get("lang")=='ar'?"scale-x-[-1]" :null}   ` }src="Assets/Icons/RightArrowHollowOrange.svg"></img></span></Link>

            </p>
          </div> 
          <div className="  w-9/12 lg:w-5/12">
            <ImageCardWithOffsettedBorder
              imageUrl={process.env.REACT_APP_BASE_URL+mission.image.data[0].attributes.url} 
            ></ImageCardWithOffsettedBorder>
          </div>
        </div>
        <div className="lg:flex container items-center justify-between  lg:w-12/12 w-10/12">
          <div className="lg:flex hidden w-9/12 lg:w-5/12">
            {" "}
            <ImageCardWithOffsettedBorder
              imageUrl={process.env.REACT_APP_BASE_URL+vision.image.data[0].attributes.url} 
            ></ImageCardWithOffsettedBorder>
          </div>
          <div className=" w-12/12 lg:w-6/12">
            <h1 className="text-4xl lg:text-6xl mb-8 font-medium">{vision.Title}</h1>
            <p className="lg:text-lg leading-10">
              {vision.previewDescription}
            </p>
        <Link to={`/seemore?id=vision`} className='text-orange  pt-1 transition-colors duration-300 m-auto  flex   mt-6 items-center hover:text-black '><div>{vision.seeMoreText}</div> <span ><img className={`mx-2 ${Cookies.get("lang")=='ar'?"scale-x-[-1]" :null}   ` }src="Assets/Icons/RightArrowHollowOrange.svg"></img></span></Link>
            
          </div>
          <div className="lg:hidden w-9/12 lg:w-5/12">
            {" "}
            <ImageCardWithOffsettedBorder
              imageUrl={process.env.REACT_APP_BASE_URL+vision.image.data[0].attributes.url} 
            ></ImageCardWithOffsettedBorder>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
