import React from 'react'
import {
    useQuery,
  } from '@tanstack/react-query'
  import axiosInstance from '../axiosConfig'
export const useFetchContent = (url,queryKey) => {
    const { isPending, error, data, isLoading } = useQuery({
        queryKey: [queryKey],
        queryFn: () =>
          axiosInstance.get(url).then((res) => res.data),
         
      })
      return { isPending, error, data, isLoading } 
}
