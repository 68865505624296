import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from '../Components/MainComponents/AboutUs/Header';
import { WhoAreWe } from '../Components/MainComponents/AboutUs/WhoAreWe';
import { WhyChooseUs } from '../Components/MainComponents/AboutUs/WhyChooseUs';
import { Values } from '../Components/MainComponents/AboutUs/Values';
import { MissionAndVison } from '../Components/MainComponents/AboutUs/MissionAndVison';
import { GenericHeader } from '../Components/MainComponents/GenericHeader';
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent';
import { Loader } from '../Components/Utilities/Loader';
  
export const AboutUsPage = () => {
  const { isPending, error, data, isLoading } = useFetchContent('/about-us?populate=deep', 'aboutUs');

  const whoAreWeRef = useRef(null);
  const whyChooseUsRef = useRef(null);
  const valuesRef = useRef(null);
  const missionAndVisionRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  if (isPending) return <Loader />;
  if (error) return 'An error has occurred: ' + error.message;

  const { who_are_we, why_choose_us, StatisticsSection, ValuesSection, mission, vision, Header } = data.data.attributes;

  return (
    <>
      <GenericHeader Header={Header} headerType="abtHeader" title="About Us" subtitle="Home / About Us" />
      <div id="who-are-we" ref={whoAreWeRef}>
        <WhoAreWe who_are_we={who_are_we} />
      </div>
      <div id="why-choose-us" ref={whyChooseUsRef}>
        <WhyChooseUs why_choose_us={why_choose_us} StatisticsSection={StatisticsSection} />
      </div>
      <div id="values" ref={valuesRef}>
        <Values ValuesSection={ValuesSection} />
      </div> 
      <div id="mission-vision" ref={missionAndVisionRef}>
        <MissionAndVison mission={mission} vision={vision} />
      </div>
    </>
  );
};
