import React, { useEffect, useState } from 'react';
import { HSHeader } from '../Components/MainComponents/HealthAndSafety.js/HSHeader';
import { GeneralDirectorSeeMore } from './GeneralDirectorSeeMore';
import { GeneralContructionSeeMore } from './GeneralContructionSeeMore';
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent';
import { GenericHeader } from '../Components/MainComponents/GenericHeader';
import { Loader } from '../Components/Utilities/Loader';

export const SeeMore = ({ pageHeader, title, desc, pic }) => {
    const [currentPage, setCurrentPage] = useState(null);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    const { isPending, error, data, isLoading } = useFetchContent('/see-more-pages?populate=deep', 'seeMore');

    useEffect(() => {
        if (data && data.data) {
            const foundPage = data.data.find(page => page.attributes.contentID === id);
            if (foundPage) {
                setCurrentPage(foundPage.attributes);
            }
        }
    }, [data, id]);

    if (isPending || isLoading) {
        return <Loader/>;
    }

    if (error) {
        return <div>An error has occurred: {error.message}</div>;
    }

    if (!currentPage) {
        return <div><Loader/></div>;
    }

    if (currentPage.contentID === "GC") {console.log("Damn");
        return <GeneralContructionSeeMore />;
    } else {
        return (
            <>
                      <GenericHeader Header={currentPage.Header} headerType={"abtHeader"} title={"About Us"} subtitle={"Home  /  About Us"}></GenericHeader>

                <div className='container mt-20 xl:w-8/12'>
                    <h1 className='text-4xl mb-5 uppercase'>{currentPage.title}</h1>
                    <p className='text-xl mb-5 leading-10'>
                        {currentPage.desc}
                    </p>
                    <img className='w-full ' src={process.env.REACT_APP_BASE_URL+currentPage.image.data.attributes.url} alt={currentPage.title} />
                </div>
            </>
        );
    }
};
