import React from 'react'
import { Link } from 'react-router-dom';

export const BottomFooter = ({infoFooter,navList,socialMedia}) => {
  return (<div className='flex justify-center bg-black'>
    <div className="px-14 pt-16   w-[90%] bg-black  ">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            
            <span className=" md:text-5xl text-3xl  tracking-wide text-white uppercase">
              <span className="text-orange">{infoFooter.title.orangeText}</span>   {infoFooter.title.whiteText}
            </span>
          </a>
          <div className="mt-6 lg:max-w-sm">
            <div className="text-lg font-light  text-white flex" >
            <img className='mx-4 '  src='Assets/Icons/Phone.svg'></img>
           <p style={{direction:"ltr"}} > {infoFooter.ContactInfo.number1} / {infoFooter.ContactInfo.number2} </p>
            </div>
            <div className="mt-4 text-lg font-light text-white flex">
            <img className='mx-4' src='Assets/Icons/Email.svg'></img>
           <p style={{direction:"ltr"}}>{infoFooter.ContactInfo.email}</p>  
            </div>
            <p className="text-xl font-medium mt-6 tracking-wide text-white">
            {infoFooter.ContactInfo.LocationTitle}
          </p>
          <Link to="https://maps.app.goo.gl/DDgVfGCSspY92QPeA"><p className="mt-3 leading-8  font-light text-white">
            {infoFooter.ContactInfo.Location}
            </p></Link> 
           <Link to="https://maps.app.goo.gl/DDgVfGCSspY92QPeA">  <p className="mt-3 leading-8  font-light text-white">
            {infoFooter.ContactInfo.Location2}
            </p></Link>
          </div>
        </div>
        <div className="space-y-5 text-sm">
        <p className="text-xl font-medium mt-6 tracking-wide text-white">
            {navList.title}
          </p>

          {navList.listItem.map((item) => {
            return (
              <div className="flex">
                <Link
                  to={item.link}
                  className="transition-colors duration-300 text-base  font-light text-m text-white hover:text-orange"
                >
                  {item.text}
                </Link>
              </div>
            );
          })}
          {/* <div className="flex">
           
            <a
              href=""
              className="transition-colors duration-300 text-base  font-light text-m  text-white hover:text-orange"
            >
              About
            </a>
          </div>



          <div className="flex">
           
          <a
              href=""
              className="transition-colors duration-300 text-base font-light text-m text-white hover:text-orange"
            >
              Our Services
            </a>
          </div>
          <div className="flex">
          <a
              href=""
              className="transition-colors duration-300 text-base  font-light text-m text-white hover:text-orange"
            >
              Projects
            </a>
          </div>
          <div className="flex">
          <a
              href=""
              className="transition-colors duration-300 text-base  font-light text-m text-white hover:text-orange"
            >
              Health & Safety
            </a>
          </div> <div className="flex">
          <a
              href=""
              className="transition-colors duration-300 text-base  font-light text-m text-white hover:text-orange"
            >
              Be Supplier
            </a>
          </div> <div className="flex">
          <a
              href=""
              className="transition-colors duration-300  text-base font-light text-m text-white hover:text-orange"
            >
              Contact Us
            </a>
          </div> */}
        </div>
        <div>
          <span className="text-base font-bold tracking-wide text-white">
            <img src='Assets/Logos/AlYafourLogoWhite.svg'></img>
          </span> <p className="text-xl font-medium mt-8 tracking-wide text-white">
            {socialMedia.title}
          </p>
          <div className="flex mx-4 items-center mt-4 justify-between">
       
            <a
              href="/"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/LinkedinIcon.svg'></img>
            </a>
            <a
              href="/"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/FbIcon.svg'></img>
            </a>
            <a
              href="/"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/IGicon.svg'></img>
            </a>
            <a
              href="/"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/TiktokIcon.svg'></img>
      
            </a>
            <a
              href="/"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/Twitter.svg'></img>
            </a>
            <a
              href="/"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/whatsapp.svg'></img>
            </a>
          </div>
          <p className="mt-4 text-sm text-white">
           
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t border-[#afb0af53] lg:flex-row">
        <p className="text-sm text-[#AFB0AF]">
          © Copyright 2020 Lorem Inc. All rights reserved.
        </p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <a
              href="/"
              className="text-sm text-[#AFB0AF] transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              F.A.Q
            </a>
          </li>
          <li>
            <a
              href="/"
              className="text-sm text-[#AFB0AF] transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="/"
              className="text-sm text-[#AFB0AF] transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Terms &amp; Conditions
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>)
}
