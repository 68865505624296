import React from "react";
import { Statistics } from "./Statistics";

export const WhyChooseUs = ({why_choose_us,StatisticsSection}) => {
  return (
    <div className="">
      <div className=" md:flex  bg-white rounded-xl mb-20 justify-between px-2 py-20 w-12/12 md:w-10/12 container">
        <div className="md:w-5/12  w-12/12 lg:m-auto  mb-10 lg:flex flex-col  items-center  ">
          <div className=" flex flex-col  ">
            <h1 className="text-5xl lg:text-6xl  font-semibold">
             {why_choose_us.Title.blackText} <span className="text-orange">{why_choose_us.Title.orangeText}</span>{" "}
            </h1>
            <p className="text-sm lg:text-xl max-w-[500px] mt-12 md:text-lg leading-9">
            {why_choose_us.description}
            </p>
            <div className="= ">
              <img
                className=" mt-10 "
                src={process.env.REACT_APP_BASE_URL+why_choose_us.image.data.attributes.url}
              ></img>
            </div>
          </div>
        </div>
        <Statistics StatisticsSection={StatisticsSection}></Statistics>
      </div>
    </div>
  );
};
