import React from "react";
import { Link } from "react-router-dom";
import { useFetchContent } from "../../Utils/CustomHooks/useFetchContent";
import Cookies from "js-cookie";
import { Loader } from "../Utilities/Loader";

export const GeneralDirectorWord = () => {

  const { isPending, error, data, isLoading } =  useFetchContent('/general-director-word?populate=*', 'GeneralDirectorWord')
  if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {Title,previewDescription,BlogText,image ,seeMore,GD_bold_title} = data.data.attributes
      







  return (
    <>
      <div className=" container mt-20">
        <div className="md:flex md:px-0 px-3 justify-between 2xl:w-10/12 xl:w-10/12 w-12/12 m-auto">
          <div className="md:w-5/12 w-full  ">
            <h1 className="text-[2.5rem] font-bold  ">
              {Title}
            </h1>
            <hr className="mb-6 mt-3"></hr>
            <p className="leading-9 text-lg  font-normal">
            {previewDescription}
            </p>
            <p className="font-semibold text-lg mt-2" >{GD_bold_title}</p>
            <Link
              to={"/GeneralDirectorSeeMore"}
              className="text-orange  pt-1 transition-colors duration-300 m-auto  flex  mt-6 items-center hover:text-black "
            >
              {seeMore+" "}
              <span>
                <img
                  className={`mx-2  ${Cookies.get("lang")=='ar'?"scale-x-[-1]" :null}`} 
                  src="Assets/Icons/RightArrowHollowOrange.svg"
                ></img>
              </span>
            </Link>
          </div>
          <div className="">
            <img
              className="h-[30rem] w-full"
              src={process.env.REACT_APP_BASE_URL+image.data.attributes.url}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};
