import Cookies from 'js-cookie'
import React from 'react'
import { Link } from 'react-router-dom'

export const BlogCard = ({left,img,title,desc,seeMoreLinkId,seeMoreText}) => {
  return (
    <>
   
       <div className=' container  mt-20' > 
     
   <div className='md:flex md:px-0 px-3 justify-between  xl:w-11/12 2xl:w-10/12 lg:w-11/12 w-12/12 m-auto'> 
    { left &&  
    <div className='md:flex lg:w-6/12 hidden'>
        <img className='w-full' src={img}>

        </img>
    </div>}
    <div className='mb-10 lg:w-6/12 px-4  '>
        <h1 className='text-[2.5rem] font-semibold mb-3  uppercase'>{title}</h1>
     
        <p className='leading-10 text-lg font-normal'>{desc}</p>
    
        <Link to={`/seemore?id=${seeMoreLinkId}`} className='text-orange  pt-1 transition-colors duration-300 m-auto  flex   mt-6 items-center hover:text-black '><div>{seeMoreText}</div> <span ><img className={`mx-2 ${Cookies.get("lang")=='ar'?"scale-x-[-1]" :null}   ` }src="Assets/Icons/RightArrowHollowOrange.svg"></img></span></Link>
    
    </div>
     { left &&  <div className=' md:6/12 w-12/12 md:hidden flex'>
        <img className='md:max-h-[400px] md:max-w-[570px]' src={img}>

        </img>
    </div>}
    { !left &&  <div className=' md:6/12 w-12/12 p=4 lg:flex md:hidden'>
        <img className='md:max-h-[400px] md:max-w-[570px]' src={img}>

        </img>
    </div>}
   </div>
   
   </div>
    </>
  )
}
