import React from 'react'
import Cookies from 'js-cookie';
export const LogosSlider = () => {
  return (<>
     <div class="slider">
      <div class={`${Cookies.get("lang")=='ar'?"slide-track" :"slide-track2"}`}>
        <div class="slide bg-red-500"><img src='/Assets/Logos/Dmt.png'></img></div>
        <div class="slide bg-purple-500"><img src='/Assets/Logos/EmiratesPost.png'></img></div>
        <div class="slide bg-blue-500"><img src='/Assets/Logos/Etisalat.png'></img></div>
        <div class="slide bg-indigo-500"><img src='/Assets/Logos/musanada.png'></img></div>
        <div class="slide bg-pink-500"><img src='/Assets/Logos/Al-Futtaim malls.png'></img></div>
        {/* <div class="slide bg-red-500"><img className='h-32' src='/Assets/Logos/ADNOC.png'></img></div> */}
        <div class="slide bg-purple-500"><img className='h-32' src='/Assets/Logos/adnc.png'></img></div>
        <div class="slide bg-blue-500"><img className='h-32' src='/Assets/Logos/sh2oon_aganb.png'></img></div>
        <div class="slide bg-indigo-500"><img className='h-32' src='/Assets/Logos/et7dya.png'></img></div>
        <div class="slide bg-pink-500"><img src='/Assets/Logos/awqaf.png'></img></div>
        <div class="slide bg-pink-500"><img src='/Assets/Logos/baldia.png'></img></div>


       


  
       
      </div>
    </div>
   
    </> 
  )
}
