import React from "react";
import { Card } from "./Card";


export const Values = ({ValuesSection}) => {
  return (
    <>
      <div className="container  m-auto xl:w-8/12 flex flex-col md:w-10/12">
        
        <div className=" lg:ml-0 ml-4">
          <h1 className=" text-5xl font-medium  mb-4"> {ValuesSection.Title}</h1>
          <p className="text-xl mb-6"> {ValuesSection.description}</p>
        </div>
        <div className="flex flex-col  lg:flex-row lg:justify-between">
 
     <Card img={process.env.REACT_APP_BASE_URL+ValuesSection.ValuesCards[0].image.data.attributes.url} text={ValuesSection.ValuesCards[0].description} title={ValuesSection.ValuesCards[0].Title}></Card>
     <Card img={process.env.REACT_APP_BASE_URL+ValuesSection.ValuesCards[1].image.data.attributes.url} text={ValuesSection.ValuesCards[1].description} title={ValuesSection.ValuesCards[1].Title}></Card>
     <Card img={process.env.REACT_APP_BASE_URL+ValuesSection.ValuesCards[2].image.data.attributes.url} text={ValuesSection.ValuesCards[2].description} title={ValuesSection.ValuesCards[2].Title}></Card>
     
     
     
    
   </div>
     </div>
     
    </>
  );
};
