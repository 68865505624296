import React from 'react'
import { AllProjectsCatalog } from '../Components/MainComponents/Projects/AllProjectsCatalog'
import { ProjectsHeader } from '../Components/MainComponents/Projects/ProjectsHeader'
import { GenericHeader } from '../Components/MainComponents/GenericHeader'
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent'
import { Loader } from '../Components/Utilities/Loader'

export const ProjectsPage = () => {

  const { isPending, error, data, isLoading } =  useFetchContent('/project-page?populate=deep', 'projectPage')
  if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {title,projects,desc,projectsNumber,navButton,Header} = data.data.attributes
      

  

  return (<>
        <GenericHeader Header={Header} headerType={"abtHeader"} title={"About Us"} subtitle={"Home  /  About Us"}></GenericHeader>
    <AllProjectsCatalog title={title} projects={projects} desc={desc} projectsNumber={projectsNumber} navButton={navButton} ></AllProjectsCatalog>
    </>
  )
}
