import React from 'react'
import { Link } from 'react-router-dom'
// import DropDownArrowBlack from '../../../assets/Icons/DropDownArrowBlack.svg'
export const NavListDropDownItem = ({children,isDark,isMenuOpen,dropdownItems,toggleMenu}) => {
  return (
   <>
   
   <div class="group ">
      <button class={`flex flex-row items-center w-full mx-2  text-base  duration-500  bg-transparent  ${isDark && !isMenuOpen  ? 'text-black' : 'text-white'}  `}>
          <span className='flex hover:text-orange font-normal '>{children}
          <img className='px-2 pt-0.5  md:di' src={ !isDark ? 'Assets/Icons/DropDownArrowWhite.svg' : 'Assets/Icons/DropDownArrowBlack.svg' }></img>
          </span>
      </button>
      <div class="absolute z-10 hidden w-[12rem] bg-grey-200 transition duration-500 ease-in-out group-hover:block rounded">
          
          <div class="pt-2 px-2 w-200 pb-2 pt-2 bg-orange bg-gray-200 shadow-lg mt-4 rounded-lg">
            <div class="grid grid-cols-1 gap-1.5 md:grid-cols-1 ">
         
              {dropdownItems.map((link)=>(<Link onClick={toggleMenu} to={`${link.link}`} className='hover:bg-white transition-colors duration-200 text-white hover:text-black px-2 py-2 w-full rounded-md'>{link.item}</Link>))}
               
            </div>
          </div>
      </div>
  </div>  
   </>
  )
}
