import React from 'react'
import { BlogCard } from '../BlogCard'


export const BlogSection = ({BlogCards}) => {
  return (<>
    {BlogCards.map((card, i) => (
      <BlogCard key={i} seeMoreText={card.seeMoreText} seeMoreLinkId={card.see_more_page.data.attributes.contentID} left={i % 2 === 1} title={card.Title} desc={card.previewDescription} img={process.env.REACT_APP_BASE_URL+card.image.data[0].attributes.url} ></BlogCard>
    ))}
    {/* <BlogCard seeMoreLinkId={"GC"} left={false} title={"GENERAL CONSTRUCTION"} desc={"we offer a wide array of services tailored to meet  the diverse needs of our clients. From routine  maintenance tasks to emergency repairs, our  skilled team delivers efficient and reliable solutions we offer a wide array of services tailored to meet  the diverse needs of our clients. From routine  maintenance tasks to emergency repairs, our  skilled team delivers efficient and reliable solutions"} img={"Assets/Images/OurServices/construct.png"} ></BlogCard>
  
  
  
  
    <BlogCard seeMoreLinkId={"GM"} left={true} title={"GENERAL MAINTINANCE"} desc={"we offer a wide array of services tailored to meet  the diverse needs of our clients. From routine  maintenance tasks to emergency repairs, our  skilled team delivers efficient and reliable solutions we offer a wide array of services tailored to meet  the diverse needs of our clients. From routine  maintenance tasks to emergency repairs, our  skilled team delivers efficient and reliable solutions"} img={"Assets/Images/OurServices/generalmaintenance.png"} ></BlogCard>
    <BlogCard seeMoreLinkId={"OG"} left={false} title={"OIL & GAS FACILITIES"} desc={"Oil and gas facilities and services encompass the  infrastructure and operations involved in the exploration, production, transportation, refining, and  distribution of oil and natural gas. This includes  activities such as drilling, refining crude oil,  constructing pipelines, transporting products, and  marketing to consumers. The industry also provides  support services like engineering, maintenance, and  compliance with environmental regulations."} img={"Assets/Images/OurServices/oil.png"} ></BlogCard>
    <BlogCard seeMoreLinkId={"IE"} left={true} title={"INFRASTRUCTURE & EARTH "} desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."} img={"Assets/Images/OurServices/infra.png"} ></BlogCard>
    <BlogCard seeMoreLinkId={"RSM"} left={false} title={"REAL STATE MANAGEMENT"} desc={"Oil and gas facilities and services encompass the  infrastructure and operations involved in the exploration, production, transportation, refining, and  distribution of oil and natural gas. This includes  activities such as drilling, refining crude oil,  constructing pipelines, transporting products, and  marketing to consumers. The industry also provides  support services like engineering, maintenance, and  compliance with environmental regulations."} img={"Assets/Images/OurServices/rs.png"} ></BlogCard>
    <BlogCard seeMoreLinkId={"TP"} left={true} title={"THIRD PARTY"} desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."} img={"Assets/Images/OurServices/3rd.png"} ></BlogCard> */}
    </>
 
  )
}
