import React from 'react'
import { CatalogMenuAsym } from '../../UI/Catalogs/CatalogMenuAsym'
import { CatalogNavigationBar } from '../../UI/Inputs/CatalogNavigationBar'
import { AllProjects } from './AllProjects'
import { AllProjectsNavBar } from './AllProjectsNavBar'
import { useFetchContent } from '../../../Utils/CustomHooks/useFetchContent'

export const AllProjectsCatalog = ({title,projects,desc,projectsNumber,navButton}) => {





  return (
    <div className='container  mt-20'>
    <div className='2xl:w-8/12  xl:w-10/12 w-12/12 m-auto  md:px-0 px-3 '>
      
       <div className='flex text-center md:text-start justify-center flex-col md:px-0 px-3 '> 
       <div className='flex items-center justify-between'>
         <h1 className='text-[2.5rem]  font-semibold'>{title}</h1>
         <p className='text-lg '>{projects.data.length} {projectsNumber} </p>
         </div>
         <p className='text-[#AFB0AF] text-lg  mt-6'>{desc}</p>
          </div>
        <AllProjectsNavBar navButton={navButton}  ></AllProjectsNavBar>
        <AllProjects projects={projects}></AllProjects>

    </div>
  
</div>
  )
}

