import React from 'react'

export const GenericHeader = ({headerType,Header,title,subtitle}) => {
  return (
    <div className={` ${headerType}  flex flex-col justify-center items-center h-[360px]`}> 

    <p className='text-white font-bold text-5xl' >{Header.title ? Header.title :title }</p>
    <p className='text-white mt-4' >{Header.subTitle?Header.subTitle:subtitle}</p>



</div>
  )
}
