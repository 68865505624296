import React, { useEffect } from 'react'

const NavListItem = ({children,isDark,isMenuOpen}) => {

  return (
    <li>
    <div className={`hover:text-orange transition-colors duration-300  font-normal ${isDark && !isMenuOpen  ? 'text-black' : 'text-white'} `}>
      {children}
    </div>
  </li>
  )
}

export default NavListItem