import React from 'react'

export const ContactUsMap = () => {
  return (
   <>
   <div className='xl:flex xl:justify-center'>
    <div className='  relative mt-24'>
         <div className='bg-white p-6 rounded-lg xl:left-7 left-12 md:top-8 top-2   absolute md:w-3/12'>
    <p className='font-bold mb-2'>Location</p>
    <p className='font-light'>Airport Road <br/>
Near Immigration Bridge Bldg .161 - Of.2 -  <br/>Abu Dhabi - UAE</p>
    
   </div>
   <a href="https://maps.app.goo.gl/DDgVfGCSspY92QPeA"><img className='rounded-xl' src='/Assets/Images/ContactUs/map.png'></img></a>
  
   </div>
   </div>
   </>
  )
}
