import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Components/UI/Catalogs/CatalogStyles.css';
import './Components/MainComponents/AboutUs/AboutUs.css';
import './Components/MainComponents/Projects/Projects.css';
import './Components/MainComponents/Headers.css';
import './Components/MainComponents/HomePage/HomePage.css';
import "./Components/Utilities/loader.css"
import Cookies from 'js-cookie';
import './Styles/ContactUs.css';


import App from './App';
import './Utils/i18n/i18n.js';
const setDirection = () => {
    const currentLang = Cookies.get('lang') || 'en';
    const direction = currentLang === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', direction);
  };
  
  const Root = () => {
    useEffect(() => {
      setDirection();
    }, []);
  
    return <App />;
  };
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Root></Root>
  
);

