import React from 'react'

export const Card = ({title,text,img}) => {
  return (
   
      <div class=" lg:w-[32%] w-[95%] lg:mb-0 mb-4 rounded-2xl pb-4 bg-white  ">
        <img
          class="w-full rounded-t-lg max-h-[275px] object-cover object-center"
          src={`${img}`}
          alt="product"
        />
        <p class="my-4 pl-4  text-lg font-medium ">
          {title}
        </p>
        <p class="mb-4 mx-4 text-sm  text-gray-800">
          {text}      
            </p>
      </div>
   
 
  )
}
