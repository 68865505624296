import React, { useEffect, useState } from "react";
import { CatalogCard } from "./CatalogCard";
import { useFetchContent } from "../../../Utils/CustomHooks/useFetchContent";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export const CatalogMenuAsym = ({projects}) => {






  const cards = [
    {
      name: "Project name",
      image: "/Assets/Images/projects/PreviewProj1.png",
      label: "Card 1",
    },
    {
      name: "Project name",
      image: "Assets/Images/projects/PreviewProj2.png",
      label: "Card 2 (spans 2 columns)",
    },
    {
      name: "Project name",
      image: "/Assets/Images/projects/PreviewProj3.png",
      label: "Card 3",
    },
    {
      name: "Project name",
      image: "/Assets/Images/projects/PreviewProj4.png",
      label: "Card 1",
    },
  ];

  return (
    <>
      <div className="grid md:grid-cols-3 grid-col-1  auto-rows-[20rem] gap-4  ">
        { projects.data.map((card, i) => (
          // <CatalogCard card={card} styling={(i) ===1 || i===2 ? "col-span-2" :"col-span-1"}></CatalogCard>
          <main
            className={`${
              i === 1 || i === 2 ? "md:col-span-2" : "md:col-span-1"
            } col-span-1 `}
          > <Link  to={`/project?id=${card.id}`} className='w-full h-full'>
            <div class={` card text-white  w-full h-full`}>
             {/* <img src={`${https://al-yafour-dashboard.onrender.com/+card.attributes.images.data[0].attributes.url}`} className=" " alt=""></img> */}
              <img src={`${process.env.REACT_APP_BASE_URL+card.attributes.images.data[0].attributes.url}`} className=" " alt=""></img>
              <div class="card-content">
                <h2
                  className={`${
                    i === 1 || i === 2 ? "double" : ""
                  } text-xl uppercase  ${Cookies.get("lang")=="ar"? "ar":null} `}
                > 
                  {card.attributes.name +`${Cookies.get("lang")=="ar"?" ← " :" → "}` }
                </h2>
                
                <p>
                {card.attributes.description}
                </p>
              </div>
            </div></Link>
          </main>
        ))}
      </div>
    </>
  );
};
