import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const LanguageToggleButton = ({ children, isDark }) => {
  const [language, setLanguage] = useState(Cookies.get('lang') || 'en');

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    Cookies.set('lang', newLanguage, { expires: 7 }); // Set cookie with a 7-day expiration
    setLanguage(newLanguage);
    // Refresh the page to apply the changes
    window.location.reload();
  };

  useEffect(() => {
    setLanguage(Cookies.get('lang') || 'en');
  }, []);

  return (
    <button 
      onClick={toggleLanguage} 
      className={`flex px- py-1.5 pr-7 hover:text-orange font-light rounded-md ${isDark ? 'text-black' : 'text-white'}`}
    >
      <img 
        className='pr-2 mx-2 pt-0.5' 
        src={isDark ? "Assets/Icons/LanguageLogoBlack.svg" : "Assets/Icons/LanguageLogoWhite.svg"} 
        alt="Language Logo"
      />
      {Cookies.get('lang') === 'en' ? 'English' : 'العربية'}
    </button>
  );
};
