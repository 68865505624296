import React from 'react'
import { LogosSlider } from '../UI/Brand/LogosSlider'

export const OurPartners = ({successPartners}) => {
  return (
    <div className='flex flex-col w-full justify-center  md:px-0 px-3  mt-20'>
      <div className='flex flex-col text-center justify-center '> <p className='text-[2.5rem] font-bold mb-6' >{successPartners.title}</p>
        <p className='mb-8  text-[#AFB0AF]'>{successPartners.description}</p>
        </div>
        <LogosSlider></LogosSlider>
    </div>
  )
}
