import Cookies from 'js-cookie';
import React from 'react'
import { Link } from 'react-router-dom';

export const CatalogMenuSym = ({services}) => {
    const cards = [
        { name: "General Construction", image: 'Assets/Images/Services/Construction.png', D: 'Card 1' },
        { name: "General Maintenance", image: 'Assets/Images/Services/Maintantance.png', label: 'Card 2 (spans 2 columns)' },
        { name: "Infrastructure & Earth Work" , image: 'Assets/Images/Services/Infrastructure.png', label: 'Card 3' },
        { name: "Oil & Gas Facilities", image: 'Assets/Images/Services/OilAndGas.png', label: 'Card 1' },
        { name: "Real Estate Management" , image: 'Assets/Images/Services/RealState.png', label: 'Card 3' },
        { name: "Third Party", image: 'Assets/Images/Services/ThirdParty.png', label: 'Card 1' },
      
      ];
    

   
  
    return (
   <>
   
   <div className='grid grid-cols-1 md:mx-0 mx-3 md:grid-cols-3 auto-rows-[20rem] mt-8 gap-4   ' >
       { services.data.map((card,i)=>(
          

            
    <main className={""}>
    <div class = {`  card text-white  w-full h-full` }>
     
      <img src={`${process.env.REACT_APP_BASE_URL+card.attributes.image.data.attributes.url}`} className='brightness-75'  alt=""></img>
  <div class="card-content">
        
        <h2 className={`text-lg uppercase ${Cookies.get("lang")=="ar"? "ar":null} `}>
            {card.attributes.name }
        </h2>    
        {/* <img src='Assets/Icons/Arrow-Right.svg'></img> */}
        <p>
        {card.attributes.previewDescription }
           </p>
        <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        
 
      </div>
    </div>
  </main>

             )
       )
 }
 

   </div>
   
   
   
   
   </>
     
   
    );
}
