import React from 'react'

export const Equipment = ({number,name,icon}) => {
  return (
    <div className='md:w-2/12 w-12/12 flex flex-col items-center'>
    <img src={icon}></img>
    <div className='flex i'>
    <p className='flex mt-2 items-center'><span className='text-orange mx-2 text-[2.5rem] '>{number}</span> {name}</p>
    </div>
</div>
  )
}
