import React from 'react'
import NavListItem from './NavListItem';
import { NavListDropDownItem } from './NavListDropDownItem';
import { ButtonRoundedWithIcon } from '../Inputs/ButtonRoundedWithIcon';
import { LanguageToggleButton } from '../Inputs/LanguageToggleButton';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

export const NavBarHamburgerMenu = ({isDark,isMenuOpen,toggleMenu,navlistbutton,navButton,toggleLang}) => {
  return (
    <ul className="lg:hidden fixed top-0 left-0 w-full h-screen bg-black flex flex-col items-center space-y-6 pt-28  z-40">
        { navlistbutton.map((listItem,index) => (<NavListDropDownItem toggleMenu={toggleMenu}
                     isDark={isDark} isMenuOpen={isMenuOpen}
                     hasDropdown={true}
                     dropdownItems={listItem.list}
                     
                     ><Link onClick={toggleMenu} to={listItem.link}>{listItem.name}</Link>
                    </NavListDropDownItem>))

}




          
                  <NavListItem isDark={isDark} isMenuOpen={isMenuOpen}><Link onClick={toggleMenu}  to="/beasupplier">{navButton[0].name}</Link></NavListItem>
                  <NavListItem isDark={isDark} isMenuOpen={isMenuOpen}><Link onClick={toggleMenu}  to="/contactus">{navButton[1].name}</Link></NavListItem>
                <div className={`${Cookies.get('lang') === 'en' ? '' : 'ml-16'}`}> <LanguageToggleButton isDark={isDark} toggleLang={toggleLang} isMenuOpen={isMenuOpen} >En</LanguageToggleButton></div> 


              </ul>
  )
}
