import React from 'react'
import { Link } from 'react-router-dom'
const AlYafourWordLogoWhite = ({isDark}) => {
  return (
    <div>
     <Link className='hi' to={"/"}> <img className='h-14' src={`${isDark? "Assets/Logos/B.png" :"Assets/Logos/w.png"}`}></img></Link>
    </div>
  )
}

export default AlYafourWordLogoWhite