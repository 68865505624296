import React from 'react'
import { Equipment } from './Equipment'

export const OurEquipments = ({EquipmentNumberSection,Title}) => {
  return (
    <>
    <div className='container 2xl:w-11/12  mt-20'>   
    <p className='text-[2.5rem] mb-10 font-semibold text-center'> <span className='text-orange'> {Title[0].orangeText}</span> {Title[0].blackText}</p>
    <div className=' justify-center md:block flex   mt-10'>
        <div className='md:flex'>
       {/* <Equipment name={"Crane"} number="1" icon="Assets/Images/OurServices/Equpiments/crane-svgrepo-com.svg"></Equipment> */}
       <Equipment name={EquipmentNumberSection.equipmentNumber[0].name} number={EquipmentNumberSection.equipmentNumber[0].number} icon="Assets/Images/OurServices/Equpiments/crane-svgrepo-com.svg"></Equipment>
      
      
       <Equipment name={EquipmentNumberSection.equipmentNumber[1].name} number={EquipmentNumberSection.equipmentNumber[1].number} icon="Assets/Images/OurServices/Equpiments/noun-cordless-concrete-vibrator-5386010.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[2].name} number={EquipmentNumberSection.equipmentNumber[2].number} icon="Assets/Images/OurServices/Equpiments/concrete-mixer-concrete-svgrepo-com.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[3].name} number={EquipmentNumberSection.equipmentNumber[3].number} icon="Assets/Images/OurServices/Equpiments/noun-crane-564338.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[4].name} number={EquipmentNumberSection.equipmentNumber[4].number} icon="Assets/Images/OurServices/Equpiments/chainsaw-svgrepo-com.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[5].name} number={EquipmentNumberSection.equipmentNumber[5].number} icon="Assets/Images/OurServices/Equpiments/noun-compactor.svg"></Equipment>

        </div>
    <div className='md:flex md:ml-0 ml-4 md:mt-10'>
       <Equipment name={EquipmentNumberSection.equipmentNumber[6].name} number={EquipmentNumberSection.equipmentNumber[6].number} icon="Assets/Images/OurServices/Equpiments/elevator-svgrepo-com.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[7].name} number={EquipmentNumberSection.equipmentNumber[7].number} icon="Assets/Images/OurServices/Equpiments/hammer-drill-2-svgrepo-com.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[8].name} number={EquipmentNumberSection.equipmentNumber[8].number} icon="Assets/Images/OurServices/Equpiments/electric-generator.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[9].name} number={EquipmentNumberSection.equipmentNumber[9].number} icon="Assets/Images/OurServices/Equpiments/caravan-trailer-svgrepo-com.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[10].name} number={EquipmentNumberSection.equipmentNumber[10].number} icon="Assets/Images/OurServices/Equpiments/bus-svgrepo-com.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[11].name} number={EquipmentNumberSection.equipmentNumber[11].number} icon="Assets/Images/OurServices/Equpiments/pick-up-truck-svgrepo-com.svg"></Equipment>
       <Equipment name={EquipmentNumberSection.equipmentNumber[12].name} number={EquipmentNumberSection.equipmentNumber[12].number} icon="Assets/Images/OurServices/Equpiments/van-svgrepo-com.svg"></Equipment>

    </div>
    </div>
    </div>
    </>
  )
}
