import { BottomFooter } from "./Components/MainComponents/BottomFooter";
import { ContactUs } from "./Components/MainComponents/ContactUs";
import NavBar from "./Components/MainComponents/NavBar";
import { OurNumbers } from "./Components/MainComponents/OurNumbers";
import { OurPartners } from "./Components/MainComponents/OurPartners";
import { AboutUsPage } from "./Pages/AboutUsPage";
import { ContactUsPage } from "./Pages/ContactUsPage";
import { GeneralContructionSeeMore } from "./Pages/GeneralContructionSeeMore";
import { GeneralDirectorSeeMore } from "./Pages/GeneralDirectorSeeMore";
import { HealthAndCare } from "./Pages/HealthAndCare";
import HomePage from "./Pages/HomePage";
import { OurServices } from "./Pages/OurServices";
import { ProjectPage } from "./Pages/ProjectPage";
import {ProjectsPage} from "./Pages/ProjectsPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { SeeMore } from "./Pages/SeeMore";
import { BeASupplierPage } from "./Pages/BeASupplierPage";
import GoToTop from "./Components/Utilities/GoToTop";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import { AppFooter } from "./Components/MainComponents/AppFooter";


const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <GoToTop/>
        <NavBar></NavBar>
          <Routes>
          
            <Route path="/about" Component={AboutUsPage} />
            <Route path="/projects" Component={ProjectsPage} />
            <Route path="/projects/:id" Component={ProjectsPage} />
            <Route path="/project" Component={ProjectPage} />
            <Route path="/ourservices" Component={OurServices} />
            <Route path="/healthandsafety" Component={HealthAndCare} />
            <Route path="/contactus" Component={ContactUsPage} />
            <Route path="/GeneralDirectorSeeMore" Component={GeneralDirectorSeeMore} />
            <Route path="/seemore?id=GC" Component={GeneralContructionSeeMore} />
            <Route path="/SeeMore" Component={SeeMore} />
            <Route path="/beasupplier" Component={BeASupplierPage} />

            <Route path="/" Component={HomePage} />
            
          </Routes>  
          <OurNumbers></OurNumbers>
       <AppFooter></AppFooter>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
