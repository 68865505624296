
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


export const CatalogNavigationBar = ({title,navbutton,description,seeMore}) => {
      const [catalogSection, setCatalogSection] = useState("Building")



  return ( 
    <div className='flex justify-center  md:justify-between mt-8 mb-8'> 
        <ul className='flex '>

            {/* <li><button className={`mr-3  px-7 py-1.5 transition-colors duration-100 font-normal text-lg rounded-md  ${catalogSection === "Building" ? "bg-orange font-bold text-white" : "bg-[#FFF] text-black" }`} onClick={()=>{setCatalogSection("Building")}} >Building</button></li> */}
         {   navbutton.map((button,index)=>(

<li><button className={`mr-3  px-7 py-1.5 transition-colors duration-100 font-normal text-lg rounded-md  ${catalogSection === button.name ? "bg-orange font-bold text-white" : "bg-[#FFF] text-black" }`} onClick={()=>{setCatalogSection(button.name)}} >{button.name}</button></li>

         ))}

            {/* <li><button className={`mr-3  px-7 py-1.5 transition-colors duration-100 font-normal text-lg rounded-md  ${catalogSection === "Infrastructure" ? "bg-orange font-bold text-white" : "bg-[#FFF] text-black" }`} onClick={()=>{setCatalogSection("Infrastructure")}} >Infrastructure</button></li>
            <li><button className={`mr-3  px-7 py-1.5 transition-colors duration-100 font-normal text-lg rounded-md  ${catalogSection === "Energy" ? "bg-orange font-bold text-white" : "bg-[#FFF] text-black" }`} onClick={()=>{setCatalogSection("Energy")}} >Energy</button></li> */}
            {/* <li><button className={`mr-3  px-7 py-1.5 transition-colors duration-100 font-light rounded-md  ${catalogSection === "Lorem" ? "bg-orange text-white" : "bg-[#FFF] text-black" }`} onClick={()=>{setCatalogSection("Lorem")}} >Lorem</button></li> */}
        </ul>

        <Link to="/projects" className='text-orange  pt-1 transition-colors duration-300 hidden md:flex items-center hover:text-black '><div>{seeMore} </div><span ><img className={`mx-2  ${Cookies.get("lang")=='ar'?"scale-x-[-1]" :null}`}  src="Assets/Icons/RightArrowHollowOrange.svg"></img></span></Link>

    </div>
  )
}
