import React from 'react'

export const ErrorPage = ({errorMessage}) => {
  return (
    <div className="loading-overlay flex flex-col">
    <div className="gg-danger"> <image src="/Assets/Icons/Arrow-Right.svg" /></div>
    <p>{errorMessage}</p>
  </div>

  )
}
