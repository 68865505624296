import React from "react";

export const AboutProject = ({area,location,cost,type,consultant,blogText}) => {
  return (<>
    <div className="container flex  2xl:w-8/12 md:justify-start justify-center items-center">
      <p className="leading-8 text-md font-light">
    {blogText}
      </p>

    </div>

    <div className="bg-white py-20 mt-20">
      <div className="container flex  2xl:w-9/12 md:justify-start justify-center items-center">
        <h1 className="text-4xl   font-medium">
          <span className="text-orange ">About </span>  Project
        </h1>
      </div>
      <div className="md:flex md:space-y-0 space-y-10 2xl:w-9/12 container my-12 justify-between">
        <div className="flex flex-col  items-center">
            <img src={process.env.REACT_APP_BASE_URL+consultant.image.data.attributes.url}></img>
            <p className="my-3 text-xl">{consultant.name}</p>
            <p className="text-xl">{consultant.number}</p>
        </div>
        <div className="flex flex-col  items-center">
            <img src="Assets/Images/Project/image-focus.svg"></img>
            {/* <p className="my-3 mt-5 text-xl">Area of Villa</p> */}
            <p className="text-2xl my-4">{area} Sqm</p>
        </div>
        <div className="flex flex-col  items-center">
            <img src="Assets/Images/Project/marker-02.svg"></img>
            <p className="mt-5 text-xl">{location}</p>
           
        </div>
        <div className="flex flex-col  items-center">
            <img src="Assets/Images/Project/coin-number.svg"></img>
            
            <p className=" mt-5 text-4xl">{cost}</p>
        </div>
      </div>
    </div></>
  );
};
