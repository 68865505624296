import axios from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_BASE_URL+"/api";
const currentLang = Cookies.get('lang') || 'en';  // Default to 'ar' if the cookie is not set

const axiosInstance = axios.create({
  baseURL: `${baseURL}`
});

axiosInstance.defaults.params = { "locale": currentLang };

export default axiosInstance;
