import React from 'react'
import { GenericHeader } from '../Components/MainComponents/GenericHeader'
import { ContactUsForm } from '../Components/MainComponents/ContactUs/ContactUsForm'
import { ContactUsMap } from '../Components/MainComponents/ContactUs/ContactUsMap'
import { Speech } from '../Components/MainComponents/ContactUs/Speech'
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent'

export const ContactUsPage = () => {

  const { isPending, error, data, isLoading } =  useFetchContent('/contact-us?populate=deep', 'contactUs')
  if (isPending) return 'Loading...'
  
  if (error) return 'An error has occurred: ' + error.message

  let  {ContactCard, ContactForm,BottomSlogan,Header} = data.data.attributes








  return (
   <>
          <GenericHeader Header={Header} headerType={"abtHeader"} title={"About Us"} subtitle={"Home  /  About Us"}></GenericHeader>

   <ContactUsForm ContactCard={ContactCard} ContactForm={ContactForm}></ContactUsForm>
   <ContactUsMap></ContactUsMap>
   <Speech BottomSlogan={BottomSlogan}> </Speech>
   </>
  )
}
