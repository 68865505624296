import React from "react";
import { Stat } from "./Stat";

export const Statistics = ({StatisticsSection}) => {
  return (
    <>
      <div className="w-12/12 md:w-5/12 mt-32 flex m-auto flex-col justify-center rounded-2xl ">
        <div className="lg:flex mx-2  mb-2">
        <Stat title={StatisticsSection.Stat[0].Title} desc={StatisticsSection.Stat[0].description} number={StatisticsSection.Stat[0].stat +"%"}></Stat>
          <Stat title={StatisticsSection.Stat[1].Title} desc={StatisticsSection.Stat[1].description} number={StatisticsSection.Stat[1].stat + "%"}></Stat>
        </div>
        <div className="lg:flex mx-2">
        
        <Stat title={StatisticsSection.Stat[2].Title} desc={StatisticsSection.Stat[2].description} number={StatisticsSection.Stat[2].stat + "%"}></Stat>
          <Stat title={StatisticsSection.Stat[3].Title} desc={StatisticsSection.Stat[3].description} number={StatisticsSection.Stat[3].stat + "%"}></Stat>
        </div>
      </div>
    </> 
  );
};
