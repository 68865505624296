import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useFetchContent } from "../../Utils/CustomHooks/useFetchContent";
import { Loader } from "../Utilities/Loader";
export const OurNumbers = () => {

  const { isPending, error, data, isLoading } =  useFetchContent('/our-number?populate=deep', 'ournumbers')
  if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {figure} = data.data.attributes
      





  return (
    <>
      {" "}
      <div className=" OurNumbers  md:h-[285px] h-[400px] mt-20 ">
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div style={{ height: 100 }}>
              {isVisible ? (
                <div className="md:flex md:flex-row  text-center justify-between mx-36 pt-[5%] md:space-y-1  space-y-[10%]">
               

                  {figure.map((item, index) => (
                    <div className="flex flex-col justify-center text-center">
                      <p className="text-2xl text-white font-semibold">
                        {item.title}
                      </p>
                      <p className="text-5xl font-bold text-white ">
                        <CountUp end={item.number} />+
                      </p>
                    </div>
                  ))}
                  {/* <div className="flex flex-col justify-center text-center">
                    <p className="text-2xl text-white font-semibold">
                      Completed
                    </p>
                    <p className="text-5xl font-bold text-white ">
                      <CountUp end={1000} />+
                    </p>
                  </div>


                  <div className="flex flex-col justify-center text-center">
                    <p className="text-2xl text-white font-semibold">Ongoing</p>
                    <p className="text-5xl font-bold text-white ">
                      <CountUp end={100} />+
                    </p>
                  </div>
                  <div className="flex flex-col justify-center text-center">
                    <p className="text-2xl text-white font-semibold">Cost</p>
                    <p className="text-5xl font-bold text-white ">
                      <CountUp end={850} />+
                    </p>
                  </div> */}
                </div>
              ) : null}
            </div>
          )}
        </VisibilitySensor>
      </div>
    </>
  );
};
