import React from 'react'

import NavBar from '../Components/MainComponents/NavBar'
import { BackgroundVideo } from '../Components/MainComponents/BackgroundVideo'
import { SloganSpeech } from '../Components/UI/Brand/SloganSpeech'
import { ProjectsCatalog } from '../Components/MainComponents/ProjectsCatalog'
import { ServicesCatalog } from '../Components/MainComponents/ServicesCatalog'
import { GeneralDirectorWord } from '../Components/MainComponents/GeneralDirectorWord'
import {
  useQuery,
} from '@tanstack/react-query'
import axiosInstance from '../Utils/axiosConfig'

const HomePage = () => {
  
//   const { isPending, error, data, isLoading } = useQuery({
//     queryKey: ['repoData'],
//     queryFn: () =>
//       axiosInstance.get('/home-page?populate=*&populate[Slogan]=*').then((res) => res.data),
     
//   })


//   if (isPending) return 'Loading...'
  
//   if (error) return 'An error has occurred: ' + error.message

//   // let Slogan,PreviewProjects]=data.data.attibutes
//  let  Slogan = data.data.attributes.Slogan
//        console.log(data);
  return (
    <>
   
   <BackgroundVideo></BackgroundVideo>
   <SloganSpeech ></SloganSpeech>
   <ProjectsCatalog></ProjectsCatalog>
   <ServicesCatalog> </ServicesCatalog>
   <GeneralDirectorWord></GeneralDirectorWord>
   
  
    </>
  )
}

export default HomePage