import React from 'react'

export const BackgroundVideo = () => {
  return (
    <div className=''>
    {/* <img className='brightness-40 absolute top-0 w-full h-full left-0 z-[-1] object-cover' src='Assets/Images/HomePage/HomeVideo.mp4'></img> */}
    <video className='brightness-40 absolute top-0 w-full h-full left-0 z-[-1] object-cover' autoPlay muted loop id="myVideo">
  <source src='Assets/Images/HomePage/HomeVideo.mp4' type="video/mp4"/>
</video>
    </div>
  )
} 
