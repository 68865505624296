import React, { useState } from 'react'
import { ButtonRounded } from '../../UI/Inputs/ButtonRounded'
import Cookies from 'js-cookie';

export const ContactUsForm = ({ContactCard,ContactForm}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState ('');
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Create the payload
    const payload = {data:
      {
      Full_Name: name,
      Phone: phone,
      Email:  email,
      Message: message}
    };

    try {
      // Send POST request
      await fetch(process.env.REACT_APP_BASE_URL+'/api/contact-us-requests', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      // Clear the form fields
      setName('');
      setPhone('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error:', error);
    }
  };




  return (
    <div className='container flex justify-center'>
        <div className='bg-[#f6f6f6] drop-shadow-xl md:rounded-[32px] md:flex mt-20 xl:w-11/12 2xl:w-10/12 '>
        <div className={ ` ${Cookies.get("lang")=="ar"?"md:rounded-r-[32px]":"md:rounded-l-[32px]"} bg-black p-10 text-white w-full md:w-5/12`}>
            <h1 className='text-white text-4xl mb-10'><span className='text-orange'>{ContactCard.Title.orangeText}</span> {ContactCard.Title.whiteText}</h1>
            <div className='border-b pb-4 mt-4'>
            <p className='font-bold text-lg mb-4'>{ContactCard.officeNumberTitle}</p>
            <p className='font-normal text-lg'>{ContactCard.OfficeNumber}</p>
            </div>

            <div className='border-b pb-4 mt-4'>
            <p className='font-bold text-lg mb-4'>{ContactCard.assistingManagerTitle}</p>
            <p className='font-normal text-lg'>{ContactCard.AssistingManager
            }</p>
            </div>
            <div className=' pb-4 mt-4'>
            <p className='font-bold text-lg mb-4'>{ContactCard.projectDepartment}</p>
            <p className='font-normal text-lg'>{ContactCard.ProjectDepartmentNo1}</p>
            <p className='font-normal mt-2 text-lg'>{ContactCard.ProjectDepartmentNo2} </p>
          
            <p className="text-xl font-medium mt-8 tracking-wide text-white">
            {ContactCard.FollowUs}
          </p>
          <div className="flex items-center mt-4  justify-start">
         
            <a
              href="/"
              className="text-white mx-2 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/LinkedinIcon.svg'></img>
            </a>
            <a
              href="/"
              className="text-white mx-2 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/FbIcon.svg'></img>
            </a>
            <a
              href="/"
              className="text-white mx-2  transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/IGIcon.svg'></img>
            </a>
            <a
              href="/"
              className="text-white mx-2 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/tiktokIcon.svg'></img>
      
            </a>
            <a
              href="/"
              className="text-white mx-2 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <img src='Assets/Icons/twitter.svg'></img>
            </a>
          </div>
          <p className="mt-4 text-sm text-white">
           
          </p>
            </div>
            

        
      
      
      
        </div>

            <div className='p-10  md:w-7/12'>
            <form onSubmit={handleSubmit}>
      <h1 className='text-4xl mb-8'>
        {ContactForm.Title.blackText} <span className='text-orange'>{ContactForm.Title.orangeText}</span><br />
      </h1>

      <p className='font-light font-sans text-lg'>{ContactForm.hint}</p>
      <div className='flex justify-between m-auto mt-6 w-full'>
        <input
          type='text'
          className='bg-[#FFF] pl-3 h-[3rem] w-[49%] rounded-[8px]'
          placeholder={ContactForm.namePlaceHolder + ' *'}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type='text'
          className='bg-[#FFF] pl-3 h-[3rem] w-[49%] rounded-[8px]'
          placeholder={ContactForm.phonePlaceholder + ' *'}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <input
        type='text'
        className='bg-[#FFF] pl-3 h-[3rem] w-full mt-6 rounded-[8px]'
        placeholder={ContactForm.emailPlaceholder + ' *'}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <textarea
        className='bg-[#FFF] p-3 h-[13rem] w-full mt-6 rounded-[8px]'
        placeholder='Message'
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      <button
        type='submit'
        className='bg-orange px-7 py-1.5 text-white font-light rounded-md w-full mt-6 h-[3rem]'
      >
        {ContactForm.submit}
      </button>
    </form>
                </div>  
        </div>

    </div>
  )
}
