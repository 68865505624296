import React from "react";
import { Carousel } from "./Carousel";

export const InfoCardWithCarousel = ({ title, desc,images,smallDescription }) => {
  // let Images = [
  //   {
  //     src: "Assets/Images/Project/CarouselImg1.png",
  //     alt: "Image 1 for carousel",
  //   },
  //   {
  //     src: "Assets/Images/Project/CarouselImg1.png",
  //     alt: "Image 2 for carousel",
  //   },
  //   {
  //     src: "Assets/Images/Project/CarouselImg1.png",
  //     alt: "Image 3 for carousel",
  //   },
  // ];

  return (
    <>
      <div className="container lg:px-0 px-3 mt-20  justify-center">
          <div className=" lg:mb-0 mb-4  px-6" >
            <h1 className="text-4xl font-medium mb-3  uppercase">{title}</h1>

            <p className="leading-10 text-lg font-light">{smallDescription}</p>
          </div>
          <div className=" w-full lg:w-8/12 m-auto my-10 justify-center ">
          <Carousel data={images}></Carousel>
          </div>
      </div>
    </>
  );
};
