import React from "react";
import { CatalogNavigationBar } from "../UI/Inputs/CatalogNavigationBar";
import { CatalogMenuAsym } from "../UI/Catalogs/CatalogMenuAsym";
import { useFetchContent } from "../../Utils/CustomHooks/useFetchContent";
import { Loader } from "../Utilities/Loader";

export const ProjectsCatalog = () => {

 

  const { isPending, error, data, isLoading } =  useFetchContent('/project-preview/?populate=deep', 'projectsPreview')
  if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {projects,navbutton,title,description,seeMore} = data.data.attributes
      
  // console.log("projects",projects.data[0].attributes.images.data[0].attributes.url);
  // console.log("projects",projects.data[0].attributes.images.data[0].attributes.url);

  console.log(navbutton);



  return (
    <>
      <div className="container  mt-20">
        <div className="2xl:w-10/12 xl:w-10/12 w-12/12 m-auto  md:px-0 px-3 ">
          {/* <div className='w-full p-4 md:w-3/12'>
          <div className='h-48 bg-orange '></div>
        </div>
       <div className='w-full p-4 md:w-9/12'>
       <div className='h-48 bg-black'>
          
        </div>
       </div> */}
          <div className="flex text-center md:text-start justify-center flex-col md:px-0 px-3 ">
            <h1 className="text-[2.5rem]  font-bold">{title}</h1>
            <p className="text-[#AFB0AF] text-lg font-normal  mt-6">
            {description}
            </p>
          </div>
          <CatalogNavigationBar seeMore={seeMore} navbutton={navbutton} ></CatalogNavigationBar>
          <CatalogMenuAsym projects={projects}></CatalogMenuAsym>
        </div>
        <a className="text-orange  pt-1 transition-colors duration-300 m-auto md:hidden flex justify-center mt-6 items-center hover:text-black ">
          {seeMore}
          <span>
            <img
              className="ml-2"
              src="Assets/Icons/RightArrowHollowOrange.svg"
            ></img>
          </span>
        </a>
      </div>
    </>
  );
};
