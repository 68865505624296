import React from 'react'
import { Link } from 'react-router-dom';
import { useFetchContent } from '../../../Utils/CustomHooks/useFetchContent';
import Cookies from 'js-cookie';

export const AllProjects = ({projects}) => {


  

  



  
   
    
      return (
        <>
          <div className="grid md:grid-cols-2 grid-col-1  auto-rows-[20rem] gap-4  ">
            {projects.data.map((card, i) => (
              // <CatalogCard card={card} styling={(i) ===1 || i===2 ? "col-span-2" :"col-span-1"}></CatalogCard>
              <main
                className={` col-span-1 `}
              > 
              <Link to={`/project?id=${card.id}`} className='w-full h-full'>
             <div class={` card text-white  w-full h-full`}>
              
              <img src={`${process.env.REACT_APP_BASE_URL+card.attributes.images.data[0].attributes.url}`} className=" " alt=""></img>
                  <div class="card-content">
                    <h2
                      className={`text-xl uppercase  ${Cookies.get("lang")=="ar"? "ar":null}`}
                    >
                      {card.attributes.name + `${Cookies.get("lang")=="ar"?" ← " :" → "}`}
                    </h2>
                    {/* <img src='Assets/Icons/Arrow-Right.svg'></img> */}
                    <p>
                      {card.attributes.description}
                    </p>
                  </div>
                  </div>
                  </Link>
              </main>
            ))}
          </div>
        </>
      );
}
