import React from 'react';

export const ButtonDarkDownload = ({ text, isSelected, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex md:w-10/12 w-full justify-between px-5 border-orange border-[1px] ${isSelected ? 'bg-orange text-white' : 'bg-white'} hover:bg-blue-600 font-light rounded-md text-black py-4 tracking-wider`}
    >
      {text}
      <img className='mr-4' src='Assets/Icons/DownloadIcon.svg' alt="Download Icon" />
    </button>
  );
};
