import React, { useState } from 'react';
import axios from 'axios';
import { GenericHeader } from '../Components/MainComponents/GenericHeader';
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent';
import { Loader } from '../Components/Utilities/Loader';

export const BeASupplierPage = () => {
  const { isPending, error, data } = useFetchContent('/be-a-supplier?populate=deep', 'beASupplier');

  const [formData, setFormData] = useState({
    Applicant_Name: '',
    Email_Address: '',
    Phone_Number: '',
    Address: '',
    Job_Title: '',
    Company_name: '',
    Category: '',
    Tax_number: '',
    Account_Holder_Name: '',
    Account_number: '',
    Account_Type: '',
  });

  const [files, setFiles] = useState({
    Trade_license: null,
    Company_profile: null,
    Tax_Certificate: null,
  });

  const [fileNames, setFileNames] = useState({
    Trade_license: '',
    Company_profile: '',
    Tax_Certificate: '',
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false); 
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (isPending) return <Loader />;
  if (error) return 'An error has occurred: ' + error.message;

  const { SupplierInfo, CompanyInfo, Attachment, submitButton, Header } = data.data.attributes;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files: fileList } = e.target;
    
    if (fileList && fileList[0]) {
      setFiles((prev) => ({ ...prev, [name]: fileList[0] }));
      setFileNames((prev) => ({ ...prev, [name]: fileList[0].name }));
    } else {
      setFiles((prev) => ({ ...prev, [name]: null }));
      setFileNames((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the submit button
    const form = new FormData();
    form.append('data', JSON.stringify(formData));
    form.append('files.Trade_license', files.Trade_license);
    form.append('files.Company_profile', files.Company_profile);
    form.append('files.Tax_Certificate', files.Tax_Certificate);

    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL+'/api/be-a-supplier-requests', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);

      setFormData({
        Applicant_Name: '',
        Email_Address: '',
        Phone_Number: '',
        Address: '',
        Job_Title: '',
        Company_name: '',
        Category: '',
        Tax_number: '',
        Account_Holder_Name: '',
        Account_number: '',
        Account_Type: '',
      });

      setFiles({
        Trade_license: null,
        Company_profile: null,
        Tax_Certificate: null,
      });

      setFileNames({
        Trade_license: '',
        Company_profile: '',
        Tax_Certificate: '',
      });

      setIsFormSubmitted(true);

      setTimeout(() => setIsFormSubmitted(false), 3000);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false); // Re-enable the submit button after request is complete
    }
  };

  return (
    <>
      <GenericHeader Header={Header} headerType={"abtHeader"} title={"Be A Supplier"} subtitle={"Home  /  Be A Supplier"} />
      
      <div className='container mt-20 w-8/12'>
        {isFormSubmitted && (
          <div className='bg-green-500 text-white p-4 rounded-lg mb-6 text-center'>
            Form submitted
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <h1 className='text-4xl mb-7 text-center font-medium'>{SupplierInfo.Title}</h1>
          <input type='text' name='Applicant_Name' value={formData.Applicant_Name} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={SupplierInfo.InputField[0].inputPlaceholder + ' *'} required />
          <input type='email' name='Email_Address' value={formData.Email_Address} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={SupplierInfo.InputField[1].inputPlaceholder + ' *'} required />
          <input type='text' name='Phone_Number' value={formData.Phone_Number} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={SupplierInfo.InputField[2].inputPlaceholder + ' *'} required />
          <input type='text' name='Address' value={formData.Address} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={SupplierInfo.InputField[3].inputPlaceholder} />
          
          <h1 className='text-4xl mb-7 text-center font-medium'>{CompanyInfo.Title}</h1>
          <input type='text' name='Company_name' value={formData.Company_name} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={CompanyInfo.InputField[0].inputPlaceholder + ' *'} required />
          <input type='text' name='Tax_number' value={formData.Tax_number} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={CompanyInfo.InputField[1].inputPlaceholder + ' *'} required />
          <input type='text' name='Job_Title' value={formData.Job_Title} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={CompanyInfo.InputField[2].inputPlaceholder + ' *'} required />

          <div className='w-full bg-[#F5F5F5] h-[3.2rem] mb-5 px-3'>
            <select name='Category' value={formData.Category} onChange={handleInputChange} className=' bg-[#F5F5F5] h-[3.2rem] w-full text-[#848584]'>
              <option value="">Select Category</option>
              <option value="Construction">Construction</option>
              <option value="Accessories">Accessories</option>
            </select>
          </div>

          <h1 className='text-4xl mb-7 text-center font-medium'>{Attachment.Title}</h1>
          
          <button
            onClick={() => document.getElementById('fileInput1').click()}
            type='button'
            className={`flex w-full justify-between px-5 bg-[#F5F5F5] text-[#848584] hover:bg-blue-600 font-light rounded-[8px] mb-5 py-4 tracking-wider ${files.Trade_license ? 'bg-gray-300' : ''}`}
          >
            {fileNames.Trade_license || Attachment.InputField[0].inputPlaceholder}
            <input id='fileInput1' type='file' name='Trade_license' onChange={handleFileChange} className='hidden' />
            <img className='mx-4' src='/Assets/Icons/uploadIcon.svg' alt='Upload' />
          </button>
          
          <button
            onClick={() => document.getElementById('fileInput2').click()}
            type='button'
            className={`flex w-full justify-between px-5 bg-[#F5F5F5] text-[#848584] hover:bg-blue-600 font-light rounded-[8px] mb-5 py-4 tracking-wider ${files.Company_profile ? 'bg-gray-300' : ''}`}
          >
            {fileNames.Company_profile || Attachment.InputField[1].inputPlaceholder}
            <input id='fileInput2' type='file' name='Company_profile' onChange={handleFileChange} className='hidden' />
            <img className='mx-4' src='/Assets/Icons/uploadIcon.svg' alt='Upload' />
          </button>

          <button
            onClick={() => document.getElementById('fileInput3').click()}
            type='button'
            className={`flex w-full justify-between px-5 bg-[#F5F5F5] text-[#848584] hover:bg-blue-600 font-light rounded-[8px] mb-5 py-4 tracking-wider ${files.Tax_Certificate ? 'bg-gray-300' : ''}`}
          >
            {fileNames.Tax_Certificate || Attachment.InputField[2].inputPlaceholder}
            <input id='fileInput3' type='file' name='Tax_Certificate' onChange={handleFileChange} className='hidden' />
            <img className='mx-4' src='/Assets/Icons/uploadIcon.svg' alt='Upload' />
          </button>
          <div className='w-full bg-[#F5F5F5] h-[3.2rem] mb-5 px-3'>
            <select name='Account_Type' value={formData.Account_Type} onChange={handleInputChange} className=' bg-[#F5F5F5] h-[3.2rem] w-full text-[#848584]'>
              <option value="Didnt select">Select Account Type</option>
              <option value="Personal">Personal Account</option>
              <option value="Enterprise">Enterprise Account</option>
            </select>
          </div>
          <input type='text' name='Account_Holder_Name' value={formData.Account_Holder_Name} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={Attachment.InputField[3].inputPlaceholder} />
          <input type='text' name='Account_number' value={formData.Account_number} onChange={handleInputChange} className='bg-[#F5F5F5] px-4 h-[3.2rem] mb-5 w-full rounded-[8px]' placeholder={Attachment.InputField[4].inputPlaceholder} />

          <div className='flex justify-center'>
          <button
            type='submit'
            disabled={isSubmitting}
            className={`bg-orange px-7 py-3 text-white font-bold rounded-md text-center w-6/12 mt-6 h-[3.5rem] ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? 'Submitting...' : submitButton}
          </button></div>
        </form>
      </div>
    </>
  );
};
