import React from 'react'

export const Loader = () => {
  return (
    <div className="loading-overlay">
    <div className="loader"></div>
    
  </div>

  )
}
