import React from 'react'
import { CatalogMenuAsym } from '../UI/Catalogs/CatalogMenuAsym'
import { CatalogMenuSym } from '../UI/Catalogs/CatalogMenuSym'
import { useFetchContent } from '../../Utils/CustomHooks/useFetchContent'
import { Loader } from '../Utilities/Loader'

export const ServicesCatalog = () => {


  const { isPending, error, data, isLoading } =  useFetchContent('/service-preview?populate=deep', 'servicesPreview')
  if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {Title,services,description} = data.data.attributes
      


  







  return (
    
    // <div className='  
    //  services flex h-[60rem] pt-20  align-top   justify-center 
    //   '  > 
    <div className='services pb-12 pt-[20px] mt-10'>
    <div className='container '>
    {/* <img src='Assets/Images/HomePage/SectionBackground.png ' className='absolute z-[-1] w-full h-[96%] top-[210vh]'></img> */}
    <div className='2xl:w-10/12 xl:w-10/12 w-12/12 m-auto mt-20 '>   
    <div className='flex text-center md:text-start justify-center flex-col md:px-0 px-3 '> 
    <h1 className='text-[2.5rem] uppercase  font-bold'>{Title}</h1>
         <p className='text-[#AFB0AF] text-lg font-normal  mt-8'>{description}</p>
          </div>
       <CatalogMenuSym services={services}></CatalogMenuSym>



    </div>
      </div>
    </div>
  )
}
