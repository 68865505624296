import React from 'react'
import { BlogCard } from '../Components/MainComponents/BlogCard'
import { BlogSection } from '../Components/MainComponents/OurServices/BlogSection'
import { HSHeader } from '../Components/MainComponents/HealthAndSafety.js/HSHeader'
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent'
import { GenericHeader } from '../Components/MainComponents/GenericHeader'
import { Loader } from '../Components/Utilities/Loader'

export const HealthAndCare = () => {

  const { isPending, error, data, isLoading } =  useFetchContent('/health-and-care?populate=deep', 'HealthandSafety')
  if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {BlogCards,Header} = data.data.attributes




  return (
<>
<GenericHeader Header={Header} headerType={"abtHeader"} title={"About Us"} subtitle={"Home  /  About Us"}></GenericHeader>


    {BlogCards.map((card, i) => (
      <BlogCard key={i} seeMoreText={card.seeMoreText} seeMoreLinkId={card.see_more_page.data.attributes.contentID} left={i % 2 === 1} title={card.Title} desc={card.previewDescription} img={process.env.REACT_APP_BASE_URL+card.image.data[0].attributes.url} ></BlogCard>
    ))}


    {/* <BlogCard seeMoreLinkId={"SP"} left={false} title={"Safety Policy"} desc={"we offer a wide array of services tailored to meet  the diverse needs of our clients. From routine  maintenance tasks to emergency repairs, our  skilled team delivers efficient and reliable solutions we offer a wide array of services tailored to meet  the diverse needs of our clients. From routine  maintenance tasks to emergency repairs, our  skilled team delivers efficient and reliable solutions"} img={"Assets/Images/HealthAndSafety.js/Safety.png"}  ></BlogCard>
    <BlogCard seeMoreLinkId={"HSE"} left={true} title={"HSE"} desc={"we offer a wide array of services tailored to meet  the diverse needs of our clients. From routine  maintenance tasks to emergency repairs, our  skilled team delivers efficient and reliable solutions we offer a wide array of services tailored to meet  the diverse needs of our clients. From routine  maintenance tasks to emergency repairs, our  skilled team delivers efficient and reliable solutions"} img={"Assets/Images/HealthAndSafety.js/Hse.png"} ></BlogCard>
    <BlogCard seeMoreLinkId={"Quality"} left={false} title={"Quality"} desc={"Oil and gas facilities and services encompass the  infrastructure and operations involved in the exploration, production, transportation, refining, and  distribution of oil and natural gas. This includes  activities such as drilling, refining crude oil,  constructing pipelines, transporting products, and  marketing to consumers. The industry also provides  support services like engineering, maintenance, and  compliance with environmental regulations."} img={"Assets/Images/HealthAndSafety.js/Quality.png"} ></BlogCard> */}
    </>
    
  )
}
