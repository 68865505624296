import React from 'react'
import FAQ from '../Components/MainComponents/FaqComponent/FAQ'
import { GenericHeader } from '../Components/MainComponents/GenericHeader'
import { Loader } from '../Components/Utilities/Loader'
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent'

export const GeneralContructionSeeMore = () => {
  const { isPending, error, data, isLoading } =  useFetchContent('/general-construction-see-more?populate=deep', 'GCSeemore')
  if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {title,Header} = data.data.attributes
      let FAQS = data.data.attributes.FAQ




  return (<>   
      <GenericHeader Header={Header} headerType={"abtHeader"} subtitle={"Home / General Director"} title={"General Director"}></GenericHeader>
    <div className='container'>
  <FAQ title={title} FAQS={FAQS}></FAQ>
  </div></>

  )
}
