import React from 'react'
import { ServicesHeader } from '../Components/MainComponents/OurServices/ServicesHeader'

import { BlogSection } from '../Components/MainComponents/OurServices/BlogSection'
import { OurEquipments } from '../Components/MainComponents/HealthAndSafety.js/OurEquipments'
import { GenericHeader } from '../Components/MainComponents/GenericHeader'
import { useFetchContent } from '../Utils/CustomHooks/useFetchContent'
import { Loader } from '../Components/Utilities/Loader'

export const OurServices = () => {



  const { isPending, error, data, isLoading } =  useFetchContent('/our-service?populate=deep', 'OurServices')
   if (isPending) return <Loader/>
  
  if (error) return 'An error has occurred: ' + error.message

  let  {BlogCard,EquipmentNumberSection,title,Header} = data.data.attributes



  return (
   <>
     <GenericHeader Header={Header} headerType={"abtHeader"} title={"About Us"} subtitle={"Home  /  About Us"}></GenericHeader>

  <BlogSection BlogCards={BlogCard}></BlogSection>
  <OurEquipments Title={title} EquipmentNumberSection={EquipmentNumberSection}> </OurEquipments>
   </>
  )
}
